import { FC, useState } from "react";
import { Translation } from "../../../../../services/translation/translation.type";
import { QuerySort, QuerySortOperator } from "@nestjsx/crud-request";
import { MyMaterialTable2VirtualizedRTK } from "../../../../../components/common/MyMaterialTable2RTK";
import {
  MyMaterialTable2PropsVirtualized,
  MyMaterialTable2Virtualized
} from "../../../../../components/common/MyMaterialTable2Virtualize";
import { TextOverflow } from "../../../../../components/common/TextOverflow";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../../reducers/app/selector";
import { useTranslation } from "react-i18next";
import {
  useGetPaginationTranslationsQuery,
  useUpdateTranslationMutation
} from "../../../../../services/translation/translation.service";
import { TranslationForm } from "./TranslationForm";
import { FormMode } from "../../../../../utils/components/form";
import { TranslationKeysTablesSchema } from "../schema";
import { RootState } from "../../../../../reducers";
import { resetLanguage } from "../../../../../utils/i18n/helper";

export interface TranslationTableProps {
  data: Translation[];
  orderRTK?: {
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  };
  onSortChange?: (sort: QuerySort) => void;
  sort?: QuerySort;
  onSearchChange?: (search: string) => void;
  search?: string;
  searchRTK?: {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
  };
  leftActions?: JSX.Element;
  rightActions?: JSX.Element;
}

export const TranslationTable: FC<TranslationTableProps> = ({
  orderRTK,
  leftActions,
  searchRTK,
  onSortChange,
  onSearchChange,
  sort,
  search,
  data,
  rightActions
}) => {
  const lang = useSelector(getLanguage);
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");
  const [rowSelected, setRowSelected] = useState<Translation | null>(null);
  const { setting } = useSelector((state: RootState) => ({
    setting: state.appSettings.setting
  }));
  const { refetch } = useGetPaginationTranslationsQuery({});

  const [
    updateTranslation,
    { isLoading: updateLoading }
  ] = useUpdateTranslationMutation();

  const onUpdate = async (values: Partial<Translation>) => {
    const { data } = (await updateTranslation(values)) as any;

    if (data && setting?.translationTimestamp) {
      resetLanguage(setting);
      await refetch();
    }
  };

  const tableProps: MyMaterialTable2PropsVirtualized<Translation> = {
    leftActions: leftActions,
    height: `calc(100vh - 250px)`,
    onRowClick: (_, rowData) => setRowSelected(rowData),
    updateElement: {
      status: updateLoading,
      title: () => t("settings.languagesSettings.translationKeys.form.edit"),
      buttonLabel: () =>
        t("settings.languagesSettings.translationKeys.form.edit"),
      formik: {
        validateOnMount: true,
        children: (props) => (
          <TranslationForm mode={FormMode.UPDATE} {...props} />
        ),
        validationSchema: TranslationKeysTablesSchema.UPDATE,
        initialValues: rowSelected
          ? {
              translation: rowSelected.translation,
              id: rowSelected.id,
              translationKey: {
                key: rowSelected.translationKey.key
              }
            }
          : {},
        onSubmit: onUpdate
      }
    },
    ...(orderRTK && {
      orderRTK: {
        orderBy: orderRTK?.orderBy as string,
        setOrderBy: orderRTK?.setOrderBy as React.Dispatch<
          React.SetStateAction<string>
        >
      },
      searchRTK: {
        search: searchRTK?.search as string,
        setSearch: searchRTK?.setSearch as React.Dispatch<
          React.SetStateAction<string>
        >
      }
    }),
    ...(onSortChange &&
      onSearchChange && {
        pagination: {
          sort: {
            onChange: (sort, direction) =>
              onSortChange &&
              onSortChange({
                field: sort,
                order: direction.toUpperCase() as QuerySortOperator
              }),
            value: {
              field: sort?.field,
              direction: sort?.order.toLowerCase() as "asc" | "desc"
            }
          },
          search: {
            value: search || "",
            onChange: onSearchChange as (search: string) => void,
            debounce: 500
          }
        }
      }),
    rightActions: rightActions ?? undefined,
    columns: [
      {
        title: t("settings.languagesSettings.translationKeys.table.key"),
        field: "translationKey.key",
        render: (_, value) => (
          <TextOverflow text={value} variant="body2" tooltip />
        )
      },
      {
        title: t("settings.languagesSettings.translationKeys.table.text"),
        field: "translation",
        render: (_, value) => (
          <TextOverflow text={value} variant="body2" tooltip />
        )
      },
      // {
      //   title: t("settings.languagesSettings.translationKeys.table.space"),
      //   field: "translationKey.nameSpace",
      //   width: 200
      // },
      {
        title: tCommon("updatedAt"),
        field: "updatedAt",
        format: (value) =>
          value &&
          new Date(value).toLocaleString(lang, {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "2-digit"
          })
      }
    ],
    data
  };

  return (
    <>
      {!orderRTK ? (
        <MyMaterialTable2Virtualized<Translation> {...tableProps} />
      ) : (
        <MyMaterialTable2VirtualizedRTK<Translation> {...tableProps} />
      )}
    </>
  );
};
