import { CSSProperties } from "react";
import { RoleStatus } from "../../entities/role";
import { User } from "../../entities/user";
import { findUserRoleStatus } from "../../reducers/authentication/selector";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { Status, StatusWOptions } from "./type";

export interface StatusOptions {
  label: string;
  value: string;
  translation?: DynamicJsonTranslator;
  style?: CSSProperties;
  isCompleted?: boolean;
  isCreation?: boolean;
  isOverdue?: boolean;
  position?: number;
}

export const transformStatus = (
  responseData: Status[],
  lang: string,
  user: User
): StatusOptions[] => {
  const role = findUserRoleStatus(user);
  const fallbackLang = localStorage.getItem("defaultLanguage");

  return responseData
    .sort((a, b) => a.position - b.position)
    .map((item) => {
      return {
        label:
          role === RoleStatus.IS_ADMIN
            ? item.internalLabel[lang] || item.internalLabel[fallbackLang!]
            : item.externalLabel[lang] || item.externalLabel[fallbackLang!],
        translation:
          role === RoleStatus.IS_ADMIN
            ? item.internalLabel
            : item.externalLabel,
        value: item.id,
        style: {
          background: item.bgColor || "white",
          color: item.fgColor || "black"
        },
        isCompleted: item.isCompleted,
        isOverdue: item.isOverdue,
        isCreation: item.isCreation
      };
    });
};

export const addOptionsToStatus = (
  responseData: Status[],
  lang: string,
  user: User
): StatusWOptions[] => {
  const role = findUserRoleStatus(user);
  const statusOptions: StatusOptions[] = [];

  return responseData

    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )
    .map((item) => {
      const currentOption: StatusOptions = {
        label:
          role === RoleStatus.IS_ADMIN
            ? item.internalLabel[lang]
            : item.externalLabel[lang],
        value: item.id,
        style: {
          background: item.bgColor || "white",
          color: item.fgColor || "black"
        }
      };
      statusOptions.push(currentOption);
      return {
        ...item,
        options: statusOptions
      };
    });
};
