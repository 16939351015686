import { BriefElementWithRelations, SideName } from "../../../entities/brief";
import {
  PaceMeasureTranslation,
  PaceMeasureUnit
} from "../../../entities/machinesOperationsRate";
import { PredictionOperation } from "../../../entities/predictionOperation";
import { jsonTranslator } from "../../../utils/function/jsonTranslator";
import i18n from "../../../utils/i18n";

export const sidePrintedLabel = (side: SideName | "No_offset") => {
  switch (side) {
    case SideName.INSIDE:
      return i18n.t("configurator:decorations.form.sidePrintedOptions.inside");
    case SideName.OUTSIDE:
      return i18n.t("configurator:decorations.form.sidePrintedOptions.outside");
    case SideName.INSIDE_AND_OUTSIDE:
      return i18n.t(
        "configurator:decorations.form.sidePrintedOptions.insideAndOutside"
      );
    case "No_offset": //Legacy
      return i18n.t(
        "configurator:decorations.form.sidePrintedOptions.noOffset"
      );
    default:
      break;
  }
};

export type RectoVerso = "recto" | "verso";

export const sufixLabel = (side: RectoVerso) =>
  side === "verso" ? " (V°)" : " (R°)";

export const getMaterialLabelFromPredictionOperation = (
  lang: string,
  operation: PredictionOperation
): string | undefined => {
  // Corrugated material
  const material = operation?.corrugatedMaterialComposition?.material;

  if (material) {
    const fluteName = material.flute?.name;
    const qualityNb = material.quality;
    const linerRecto =
      material?.rectoLiner?.label &&
      jsonTranslator(material.rectoLiner.label, lang);
    const linerVerso =
      material?.versoLiner?.label &&
      jsonTranslator(material.versoLiner.label, lang);
    return [fluteName, qualityNb, linerRecto, linerVerso]
      .filter((e) => e)
      .join(" ");
  }

  // Folding material
  const materialReference = operation?.materialReference;

  if (materialReference) {
    return materialReference?.label
      ? jsonTranslator(materialReference.label, lang)
      : materialReference?.name;
  }
};

export const getMaterialLabel = (
  lang: string,
  element?: BriefElementWithRelations
) => {
  if (!element) return;
  if (element?.corrugatedMaterialId) {
    const fluteName = element.materialFlute && element.materialFlute.name;
    const qualityNb = element.materialQuality;
    const linerRecto =
      element.materialLinerRecto &&
      jsonTranslator(element.materialLinerRecto.label, lang);
    const linerVerso =
      element.materialLinerVerso &&
      jsonTranslator(element.materialLinerVerso.label, lang);
    return [fluteName, qualityNb, linerRecto, linerVerso]
      .filter((e) => e)
      .join(" ");
  } else if (element?.materialReference) {
    return jsonTranslator(element.materialReference.label, lang);
  }
};

export const getDurationLabel = (
  floatHours: number,
  format: "string" | "time" = "string",
  separator = ""
) => {
  const hourInt = Math.floor(floatHours);

  const hourStr =
    hourInt !== 0 ? `${hourInt} ${i18n.t("briefDetail:unit.hours")}` : "";

  const minInt = Math.round((floatHours - hourInt) * 60);
  const minStr =
    minInt !== 0 || hourInt === 0
      ? `${minInt} ${i18n.t("briefDetail:unit.minutes")}`
      : "";

  if (format === "time") {
    const hourStr = hourInt.toString().padStart(2, "0");
    const minStr = minInt.toString().padStart(2, "0");
    return `${hourStr}${separator}${minStr}`;
  }

  return [hourStr, minStr].filter((e) => e !== "").join(" ");
};

export const convertToHoursOld = (values: any): Record<string, number> => {
  const key = Object.keys(values)[0].split("_")[0];
  const hours = (Object.values(values)[0] as number) || 0;
  const minutes = (Object.values(values)[1] as number) || 0;
  const minutesToHours = minutes / 60;
  return { [key]: hours + minutesToHours };
};

/* export const convertToHours = (values: any): Record<string, number> => {
  const key = Object.keys(values)[0];
  const value = Object.values(values)[0] as string;
  const hours = parseInt(value.split(":")[0]) || 0;
  const minutes = parseInt(value.split(":")[1]) || 0;
  const minutesToHours = minutes / 60;
  return { [key]: hours + minutesToHours };
}; */

export const convertToHours = (
  values: Record<string, string>
): Record<string, number> => {
  const key = Object.keys(values)[0];
  const value = values[key];
  const [hoursStr, minutesStr] = value.split(":");

  const hours = parseInt(hoursStr, 10) || 0;
  let minutes = parseInt(minutesStr, 10) || 0;

  // Assurer que les minutes ne dépassent pas 59
  if (minutes > 59) {
    minutes = 59;
  }

  const minutesToHours = minutes / 60;

  return { [key]: parseFloat((hours + minutesToHours).toFixed(8)) };
};

export const getDurationLabelArray = (floatHours: number) => {
  const hourInt = Math.floor(floatHours);
  const hourStr =
    hourInt !== 0 ? `${hourInt} ${i18n.t("briefDetail:unit.hours")}` : "";
  const minInt = Math.floor((floatHours - hourInt) * 60);
  const minStr =
    minInt !== 0 || hourInt === 0
      ? `${minInt} ${i18n.t("briefDetail:unit.minutes")}`
      : "";
  return [hourStr, minStr];
};

export const getUnitFromDurationLabel = (durationLabel: string): string => {
  return durationLabel.includes(i18n.t("briefDetail:unit.hours")) ? "h" : "m";
};

export const parseDurationLabel = (durationLabel: string): number => {
  const hourRegex = /(\d+)\s+heures?/;
  const minuteRegex = /(\d+)\s+minutes?/;
  const hourMatch = durationLabel.match(hourRegex);
  const minuteMatch = durationLabel.match(minuteRegex);
  const hours = hourMatch ? parseInt(hourMatch[1], 10) : 0;
  const minutes = minuteMatch ? parseInt(minuteMatch[1], 10) : 0;
  return hours + minutes / 60;
};

export const getPercentageLabel = (
  floatPct: number | null | undefined,
  fractionDigits?: number
) => {
  if (floatPct === null || floatPct === undefined) {
    return "-";
  }
  return `${(floatPct * 100).toFixed(fractionDigits)} %`;
};

export const getFloat = (float: number, fractionDigits: number): number => {
  return Number.parseFloat(float.toFixed(fractionDigits));
};

export const getPaceByMeasureUnit = (
  pace: number,
  unit: PaceMeasureUnit
): string => {
  if (!pace || !unit) return "";
  return `${pace.toFixed(2)} ${i18n.t(
    `backoffice:${PaceMeasureTranslation[unit]}D`
  )}`;
};

export function navigateToBrief(briefId?: string, tab?: number): string {
  if (!briefId) return "#not-found";
  if (tab) {
    return `/briefs/${briefId}?tab=${tab}`;
  }
  return `/briefs/${briefId}`;
}

export function formatSetupTime(value: string): string {
  const formatValue = value.toString().padStart(4, "0");
  return `${formatValue.slice(0, 2)}:${formatValue.slice(2)}`;
}
