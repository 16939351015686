import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  IconButton,
  InputBaseComponentProps,
  makeStyles,
  OutlinedInput,
  Theme
} from "@material-ui/core";
import { InputProps } from "@material-ui/core/Input";
import EventIcon from "@material-ui/icons/Event";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Field, FieldProps } from "formik";
import { DebouncedFunc, get } from "lodash";
import React, { useMemo } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { useSelector } from "react-redux";
import { EditableField } from "../../../pages/Configurator/components/common/BriefElementForm";
import { getLanguage } from "../../../reducers/app/selector";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";
import { MyInputBox, MyInputBoxProps } from "./MyInputBox";
import {
  getFormatByLang,
  getLocaleForFns
} from "../../../utils/function/formatDate";

interface MyNumberFormatProps extends NumberFormatProps {
  inputValue?: (value: number) => void;
  outputValue?: (loatValue: number) => void;
}

const useOutlinedInputStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(13, 78, 255)"
    }
  },
  time: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      filter: `opacity(0.5) drop-shadow(0 0 0 ${theme.palette.primary.main}) !important`
    }
  }
}));

export interface MyTextFieldProps extends InputProps, FormProps {
  label: string;
  placeholder?: string;
  isLock?: boolean;
  value?: any;
  disabledFloatNumber?: boolean;
  numberFormatProps?: MyNumberFormatProps;
  onLockClick?: (name: keyof EditableField, lock: boolean) => void;
  inputBoxProps?: MyInputBoxProps;
  textFormat?: (value: string) => string;
  touched?: boolean;
  onDebounceChange?: DebouncedFunc<
    (
      values: {
        [key: string]: string | string[];
      },
      name: string,
      currentValue: string
    ) => void
  >;
  hidden?: boolean;
  getTime?: boolean;
  // Add check to avoid formik error when it's not a customfield node (avoid regression)
  isCustomField?: boolean;
  onlyTime?: boolean;
}

const NumberFormatComponent = (props: InputBaseComponentProps) => {
  const {
    inputRef,
    onChange,
    name,
    value,
    numberformatprops: { inputValue, outputValue, ...numberformatprops },
    ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      {...numberformatprops}
      getInputRef={inputRef}
      name={name}
      isNumericString
      allowedDecimalSeparators={[",", "."]}
      value={value && inputValue ? inputValue(Number(value)) : value}
      onValueChange={({ floatValue }) =>
        onChange &&
        onChange({
          target: {
            value:
              floatValue && outputValue ? outputValue(floatValue) : floatValue,
            name
          }
        } as any)
      }
    />
  );
};

export const MyTextField: React.FC<MyTextFieldProps> = React.memo(
  function MyTextField(props) {
    // Classes
    const outlinedInputStyles = useOutlinedInputStyles();

    //React.memo(
    const {
      label,
      helperText,
      formControlProps,
      formLabelProps,
      formHelperTextProps,
      onDebounceChange,
      isLock,
      onLockClick,
      value: textFieldValue,
      disabledFloatNumber,
      numberFormatProps,
      inputBoxProps,
      textFormat,
      touched,
      hidden,
      getTime,
      isCustomField,
      onAbort,
      onlyTime,
      onChange,
      ...inputProps
    } = props;
    /* Refs */
    const labelRef = React.useRef<HTMLLabelElement>(null);
    const textRef = React.useRef<HTMLInputElement>(null);

    /* States */
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [lock, setLock] = React.useState<boolean>();

    /* Selectors */
    const lang = useSelector(getLanguage);

    /* Memos */
    // Filter the date format by language
    const { locale, format } = useMemo(() => {
      return {
        locale: getLocaleForFns(lang),
        format: getFormatByLang(lang, getTime)
      };
    }, [getTime, lang]);

    /* Effects */
    React.useEffect(() => {
      labelRef.current && setLabelWidth(labelRef.current.offsetWidth);
      const currentText = textRef.current;

      currentText &&
        currentText.addEventListener("onWheel", (e) => e.preventDefault());
      return () => {
        textRef &&
          currentText &&
          currentText.removeEventListener("wheel", (e) => e.preventDefault());
      };
    }, []);

    React.useEffect(() => {
      if (typeof isLock === "boolean") {
        setLock(isLock);
      }
    }, [isLock]);

    return (
      <Field name={props.name}>
        {({ field, form }: FieldProps<any>) => {
          const errorField = get(form.errors, field.name);
          const touchField = get(form.touched, field.name) || touched;
          let value: any;

          if (textFieldValue !== undefined) {
            value = textFieldValue;
          } else if (field.value !== undefined) {
            value = field.value;
          } else {
            value = "";
          }

          /* 
          Handle case where customfield number are not in the formik values state
          */
          //TODO check if still useful (see default value of formik values with customfield forms)
          if (isCustomField) {
            const customFieldPart = field.name.split(".");
            const customFieldPrefix = customFieldPart[0];
            const customFieldName = customFieldPart[1];
            const customFieldValues = form.values[customFieldPrefix];
            if (
              customFieldValues &&
              customFieldName &&
              !Object.keys(customFieldValues ?? {}).includes(customFieldName)
            ) {
              form.setFieldValue(field.name, null);
            }
          }

          const myFormControlProps: MyFormControlProps = {
            errorField,
            touchField,
            label,
            labelRef,
            helperText,
            disabled: inputProps.disabled,
            hidden: formControlProps?.hidden || hidden,
            formLabelProps: {
              ...formLabelProps,
              required: inputProps.required || false
            },
            formHelperTextProps
          };

          return (
            <MyInputBox fullWidth {...inputBoxProps}>
              <MyFormControl
                {...formControlProps}
                {...myFormControlProps}
                margin="dense"
                isAnInputLabel={true}
                showComponentLabel={inputProps.type !== "date"}
              >
                {inputProps.type === "date" ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                    {getTime ? (
                      <KeyboardDateTimePicker
                        lang={lang}
                        views={["year", "month", "date", "hours", "minutes"]}
                        clearable
                        ampm={false}
                        autoOk
                        label={label}
                        InputLabelProps={{
                          style: {
                            color: "black"
                          }
                        }}
                        KeyboardButtonProps={{
                          style: {
                            color: "rgba(0, 0, 0, 0.87)",
                            padding: "0"
                          }
                        }}
                        keyboardIcon={<EventIcon style={{ fontSize: 18 }} />}
                        margin="dense"
                        format={format}
                        {...field}
                        inputVariant="outlined"
                        InputAdornmentProps={{
                          position: "end"
                        }}
                        onChange={(date) => {
                          field.onChange({
                            target: { value: date, name: field.name }
                          });
                        }}
                      />
                    ) : (
                      <KeyboardDatePicker
                        //views={["year", "month", "date"]}
                        /*  clearable */
                        autoOk
                        label={label}
                        InputLabelProps={{
                          style: {
                            color: "black"
                          }
                        }}
                        KeyboardButtonProps={{
                          style: {
                            color: "rgba(0, 0, 0, 0.87)",
                            padding: "0"
                          }
                        }}
                        keyboardIcon={<EventIcon style={{ fontSize: 18 }} />}
                        margin="dense"
                        variant="inline"
                        format={format}
                        {...field}
                        inputVariant="outlined"
                        InputAdornmentProps={{
                          position: "end"
                        }}
                        onChange={(date) =>
                          field.onChange({
                            target: { value: date, name: field.name }
                          })
                        }
                      />
                    )}
                  </MuiPickersUtilsProvider>
                ) : (
                  <OutlinedInput
                    ref={textRef}
                    autoCorrect="off"
                    autoCapitalize="off"
                    margin="dense"
                    classes={{
                      ...(inputProps.type === "time" && {
                        root: outlinedInputStyles.time
                      }),
                      ...(onDebounceChange &&
                        !errorField && {
                          root: outlinedInputStyles.root
                        })
                    }}
                    labelWidth={labelWidth}
                    error={!!errorField && !!touchField}
                    {...field}
                    {...inputProps}
                    onChange={(e) => {
                      const newVal =
                        !!e.target.value && textFormat
                          ? textFormat(e.target.value)
                          : e.target.value;
                      field.onChange({
                        target: { value: newVal, name: field.name }
                      });
                      if (!touchField) {
                        form.setFieldTouched(field.name, true);
                      }
                      if (onDebounceChange) {
                        onDebounceChange(
                          form.values,
                          props.name as string,
                          newVal
                        );
                      }
                      onChange && onChange(e);
                    }}
                    type={
                      inputProps.type === "number" ? "text" : inputProps.type
                    }
                    inputProps={{
                      numberformatprops: {
                        thousandSeparator: disabledFloatNumber
                          ? " "
                          : undefined,
                        ...numberFormatProps
                      },
                      inputMode:
                        inputProps.type === "number" ? "decimal" : undefined,
                      ...inputProps.inputProps
                    }}
                    inputComponent={
                      inputProps.type === "number"
                        ? NumberFormatComponent
                        : ("input" as const)
                    }
                    value={value}
                    disabled={lock || inputProps.disabled}
                    endAdornment={
                      <Box display="flex" alignItems="center" ml={1 / 2}>
                        {inputProps.endAdornment}
                        {lock !== undefined && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              onLockClick
                                ? onLockClick(
                                    field.name as keyof EditableField,
                                    !lock
                                  )
                                : setLock(!lock)
                            }
                          >
                            {lock ? (
                              <LockIcon color="disabled" />
                            ) : (
                              <LockOpenIcon color="action" />
                            )}
                          </IconButton>
                        )}
                      </Box>
                    }
                  />
                )}
              </MyFormControl>
            </MyInputBox>
          );
        }}
      </Field>
    );
  }
);
