import { Localisations } from "../../entities/localisations";

export function findBestMatchLanguage(
  backendLanguages: Localisations[],
  browserLanguages: readonly string[]
): Localisations | null {
  const enabledLanguages = backendLanguages.filter((lang) => lang.enable);

  for (const browserLang of browserLanguages) {
    const exactMatch = enabledLanguages.find(
      (lang) => lang.lang === browserLang
    );
    if (exactMatch) {
      return exactMatch;
    }
  }

  for (const browserLang of browserLanguages) {
    const browserLangCode = browserLang.split("-")[0];
    const partialMatch = enabledLanguages.find(
      (lang) => lang.lang.split("-")[0] === browserLangCode
    );
    if (partialMatch) {
      return partialMatch;
    }
  }

  const defaultLang = enabledLanguages.find((lang) => lang.default);
  if (defaultLang) {
    return defaultLang;
  }

  return null;
}
